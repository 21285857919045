@import 'styles/helpers/helpers';

.errorPage {
  padding-top: 48px;
  padding-bottom: 54px;
  height: 100vh;

  &__body {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__logo {
    max-width: 213.37px;
    justify-self: flex-start;
  }

  &__list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    flex: 1 1 50%;
  }

  &__Imagelist {
    flex: 1 1 35%;
  }

  &__image {
    max-width: 371px;
    width: 100%;
  }

  &__contentTitle {
    @include globalBoldFont();
    font-size: 24px;
    line-height: 26px;
    color: $main_text_color;
    padding-bottom: 32px;
  }

  &__contentDescription {
    @include globalRegularFont();
    font-size: 18px;
    line-height: 24px;
    color: $additional_text_color;
  }

  &__contentButtonsList {
    margin-top: 65px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__contentButtonBack {
    @include globalSemiBoldFont();
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $main_text_color;
    background-color: transparent;
    margin-left: 23px;

    & img {
      margin-right: 16px;
    }
  }

  &__button {
    padding: 16px 86.5px;
  }

  &__error {
    @include globalMediumFont();
    font-size: 16px;
    line-height: 22px;
    color: $additional_text_color;
    align-self: center;
  }
}

@media (max-width: 1138px) {
  .errorPage__body {
    padding: 0 15px;
  }

  .errorPage__image {
    margin-right: 40px;
  }
}

@media (max-width: 1000px) {
  .errorPage__image {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .errorPage__list {
    flex-direction: column;
  }
}

@media (max-width: 538px) {
  .errorPage__contentButtonsList {
    flex-direction: column;
    align-items: flex-start;
  }

  .errorPage__contentButtonBack {
    margin-bottom: 30px;
  }

  .errorPage__contentTitle {
    font-size: 20px;
  }

  .errorPage__list {
    padding: 30px 0;
  }

  .errorPage__body {
    padding-bottom: 30px;
  }

  .errorPage__body {
    height: auto;
  }
}
